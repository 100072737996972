import React, { useEffect, useState } from 'react'
import { Badge, Button, ButtonGroup, ButtonToolbar, Card, Col, Container, FormControl, InputGroup, Row, Spinner, Table, ToastContainer } from 'react-bootstrap'
import * as Icons from 'react-bootstrap-icons'
import { Link, Redirect } from 'react-router-dom'
import axios from 'axios';

export default function MenuList() {
    const [l, setl]:any = useState([])
    const [v, setv]:any = useState({})

    useEffect(()=>{
        axios.get('core/menu').then(res=>{
            setl(res.data.data)

            let d = res.data.data.find((r:any)=>r.uid==='hdfgd323sdha')
            console.log(d);
            
        })
    },[])
    
    return (
        <Container className="mt-2">
<ToastContainer />

<Card>
<Card.Header as="h5" className="d-print-none">Menu <Badge bg="info">List</Badge>
<div className="float-end">
<ButtonToolbar aria-label="Toolbar with Button groups">
<ButtonGroup aria-label="First group" className=" mt-1">
<Spinner animation="grow" className={v.islist?'':'d-none'} />
<Link to="/core/menu"><Button size="sm" variant="outline-secondary" className={"mx-1 "+ (v.action === 'new' ? 'd-none' : '')}><Icons.FilePlusFill size="18px" /></Button></Link>
</ButtonGroup>

</ButtonToolbar>

</div>

</Card.Header>

<Card.Body>
<Table striped bordered hover size="sm">
<thead>
<tr>
<th>#</th>
<th>User Group</th>
<th>Action</th>
</tr>
</thead>
<tbody>
{l.map((r: any, i:number) => {
return (
<tr key={i}>
<td style={{width:"20px"}}>{i + 1}</td>
<td>{r.menu}</td>
<td style={{width:"20px"}}><Link to={"/core/menu/"+r.uid}><Icons.PencilSquare className="text-info ms-3" style={{ cursor: "hand" }} /></Link></td>
</tr>
)
})
}

</tbody>
</Table>
</Card.Body>

</Card>
</Container>
    )
}
