import './App.css';
import { BrowserRouter as Router, Route,Switch } from 'react-router-dom'
import DataProvider, { MenuProvider, RouteProvider } from './dataContext';
import { useEffect } from 'react';
import { Alert } from 'bootstrap';

import Footer from './Footer'
import Topbar from './Topbar'
import Dashboard from './Dashboard'

import Login from './core/login'
import LedgerGroup from './core/ledgerGroup';
import LedgerCategory from './core/ledgerCategory';
import Menu from './core/menu';
import MenuList from './core/menuList';
import UserGroup from './core/userGroup'


import Ledger from './billing/ledger'
import SalesInvoiceForm from './billing/salesInvoiceForm'
import SalesInvoiceList from './billing/salesInvoiceList'

import WeeklyWages from './payroll/report/weeklyWages';
import punchLog from './payroll/report/punchLog';
import PayrollReport from './payroll/report/payrollReport';
import JewellerySalesInvoicePrint from './jewellery/salesInvoicePrint';
//import JewellerySalesInvoice from './jewellery/salesInvoice';
import DeviceLog from './payroll/deviceLog';
import Employee from './payroll/employee';
import EmployeeList from './payroll/employeeList';

//import SalesInvoice from './jewellery/SalesInvoice'


function App() {
  useEffect(() => {
    console.log('test');
    
    if (localStorage.token){
    // var popoverTriggerList = [].slice.call(document.querySelectorAll('[data-toggle="popover"]'))
    //     // var popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
    //     //   return new Popover(popoverTriggerEl)
    //     // })
        
        /* ==== Enable Bootstrap Alert ====== */
        var alertList = document.querySelectorAll('.alert')
        alertList.forEach(function (alert) {
          new Alert(alert)
        });
        
        
        /* ===== Responsive Sidepanel ====== */
        const sidePanelToggler = document.getElementById('sidepanel-toggler'); 
        const sidePanel = document.getElementById('app-sidepanel');  
        const sidePanelDrop = document.getElementById('sidepanel-drop'); 
        const sidePanelClose = document.getElementById('sidepanel-close'); 
        
        window.addEventListener('load', function(){
            responsiveSidePanel(); 
        });
        
        window.addEventListener('resize', function(){
            responsiveSidePanel(); 
        });
        
        
        function responsiveSidePanel() {
            let w = window.innerWidth;
            if(w >= 1200) {
                // if larger 
                //console.log('larger');
                if (sidePanel) sidePanel.classList.remove('sidepanel-hidden');
                if (sidePanel) sidePanel.classList.add('sidepanel-visible');
                
            } else {
                // if smaller
                //console.log('smaller');
                if (sidePanel) sidePanel.classList.remove('sidepanel-visible');
                if (sidePanel) sidePanel.classList.add('sidepanel-hidden');
            }
        }
        
        if(sidePanelToggler != null && sidePanel != null) {
        sidePanelToggler.addEventListener('click', () => {
            if (sidePanel.classList.contains('sidepanel-visible')) {
                sidePanel.classList.remove('sidepanel-visible');
                sidePanel.classList.add('sidepanel-hidden');
                
            } else {
                console.log('hidden');
                sidePanel.classList.remove('sidepanel-hidden');
                sidePanel.classList.add('sidepanel-visible');
            }
        })

        
        if (sidePanelClose){
        sidePanelClose.addEventListener('click', (e) => {
            e.preventDefault();
            sidePanelToggler.click();
        })
        }else{
            alert(sidePanelClose)
        }
        
        if (sidePanelDrop){
        sidePanelDrop.addEventListener('click', (e) => {
            sidePanelToggler.click();
        });
        }
        
    }
        
        }
})
  return (
    <>
      <DataProvider>
        <MenuProvider>
          <RouteProvider>
            <Router>

              {localStorage.token ?
              <>
                <Route path="/jewellery/salesInvoice/print/:id" component={JewellerySalesInvoicePrint} exact={true} />
            <div className="app">
                  <Topbar></Topbar>
                  
                  <div className="app-wrapper  ">

                  <Switch>
                      <Route path="/" component={Dashboard} exact={true}  />
                      <Route path="/dashboard" component={Dashboard} exact={true}  />
                      <Route path="/core/login" component={Login} exact={true}  />
                      {/* <Route path="/receiptNote" component={ReceiptNote} exact={true} /> */}

                      <Route path="/billing/ledger" component={Ledger} exact={true}  />
                      <Route path="/billing/salesInvoice" component={SalesInvoiceForm} exact={true}  />
                      <Route path="/billing/salesInvoice/list" component={SalesInvoiceList} exact={true}  />

                      {/* <Route path="/jewellery/salesInvoice/list" component={JewellerySalesInvoiceList} exact={true} /> */}
                      {/* <Route path="/jewellery/salesInvoice/:id" component={JewellerySalesInvoice} exact={true} /> */}
                      {/* <Route path="/jewellery/salesInvoice" component={JewellerySalesInvoice} exact={true} /> */}
                      {/* <Route path="/billing/receiptNote/list" component={ReceiptNote} exact={true}  /> */}
                      {/* <Route path="/billing/receiptNote/:id" component={ReceiptNote} exact={true} /> */}
                      <Route path="/core/ledgerCategory" component={LedgerCategory} exact={true} />
                      <Route path="/core/ledgerCategory/list" component={LedgerCategory} exact={true} />
                      <Route path="/core/ledgerCategory/:id" component={LedgerCategory} exact={true} />
                      <Route path="/core/ledgerCategory/print/:id" component={LedgerCategory} exact={true} />

                      <Route path="/core/ledgerGroup" component={LedgerGroup} exact={true}  />
                      <Route path="/core/ledgerGroup/list" component={LedgerGroup} exact={true} />
                      <Route path="/core/ledgerGroup/:id" component={LedgerGroup} exact={true} />
                      <Route path="/core/userGroup" component={UserGroup} exact={true}  />
                      <Route path="/core/userGroup/list" component={UserGroup}  exact={true} />
                      <Route path="/core/userGroup/:id" component={UserGroup} exact={true} />
                      <Route path="/core/menu" component={Menu} exact={true}  />
                      <Route path="/core/menu/list" component={MenuList}  exact={true} />
                      <Route path="/core/menu/:id" component={Menu} exact={true} />

                      <Route path="/payroll/employee/list" component={EmployeeList} exact={true} />
                      <Route path="/payroll/employee/:id" component={Employee} exact={true} />
                      <Route path="/payroll/employee" component={Employee} exact={true} />
                      <Route path="/payroll/deviceLog" component={DeviceLog} exact={true} />
                      <Route path="/payroll/report" component={PayrollReport} exact={true} />
                      <Route path="/payroll/report/weeklyWages" component={WeeklyWages} exact={true} />
                      <Route path="/payroll/report/punchLog" component={punchLog} exact={true} />

                      </Switch>
                    <Footer></Footer>
                  </div>
                </div>
                </>
                : <Login></Login>
                }




            </Router>
          </RouteProvider>
        </MenuProvider>

      </DataProvider>
    </>
  );
}

export default App;
