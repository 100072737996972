import React, { useEffect, useState } from 'react'
import { Badge, Button, ButtonGroup, ButtonToolbar, Card, Col, Container, FormControl, InputGroup, Row, Spinner, Table, ToastContainer } from 'react-bootstrap'
import axios from 'axios';
import  * as Icons  from 'react-bootstrap-icons';
import { useLocation,matchPath, useRouteMatch, useParams } from 'react-router-dom'

interface types{
    isload:boolean,
    l:any,
}
export default class WeeklyWages extends React.Component<any,types> {
constructor(props: any) {
    super(props)
    this.state = {l:{logs:[],datesbywords:[]},isload:true}
}

componentDidMount() {
    let q = Object.fromEntries(new URLSearchParams(this.props.location.search))
    console.log(q);
    
    axios.get('/payroll/report/weeklyWages',{params:q}).then(res=>{
        this.setState({...this.state,l:res.data,isload:false})
    })
}

render(){
return(
<Card className='m-2'>
<ToastContainer />
<Card.Header as="h5" className="d-print-none">Weekly Wages
<div className="float-end">
<ButtonToolbar aria-label="Toolbar with Button groups">
<ButtonGroup aria-label="First group" className=" mt-1">
<Spinner animation="grow" className={this.state.isload?'':'d-none'} />

<Button size="sm" variant="outline-success" className={"mx-1 "+this.state.isload?'d-none':''} title="Save [Alt + S]" type="submit" form="form"><Icons.ArrowLeft size="16px" /></Button>
</ButtonGroup>

</ButtonToolbar>

</div>

</Card.Header>

<Card.Body className="d-print-block">
<Table striped bordered hover size="sm" className="d-print-block">
<thead>
<tr className="bg-warning">
<th className='text-center'>#</th>
<th className='text-center'>Name</th>
<th className='text-center'>Emp Code</th>
<th className='text-center'>Designation</th>
<th className='text-center'>Department</th>
<th className='text-center'>Wage /Shift</th>
<th className='text-center'>Shift</th>

{
    this.state.l.datesbywords.map((dw:any)=>{
        return (<th className='text-center'>{dw}</th>)
    })

}
<th className='text-center'>Shift Total</th>
<th className='text-center'>Amount</th>
</tr>
</thead>
<tbody>
{this.state.l.logs.map((r: any, i:number) => {
return (
<tr key={i}>
<td style={{width:"20px"}}>{i + 1}</td>
<td>{r.employee}</td>
<td style={{width:"30px"}}>{r.UserId}</td>
<td style={{width:"30px"}}>{r.designation}</td>
<td style={{width:"30px"}}>{r.department}</td>
<td style={{width:"30px"}} className='text-center'>{r.salary}</td>
<td style={{width:"60px"}}>{r.shift}</td>
{r.shifts.map((d:string)=>{return(<td style={{width:"20px"}} className='text-center'>{d}</td>)})}
<td style={{width:"30px"}} className='text-center  fw-bold'>{r.shiftTotal}</td>
<td style={{width:"30px"}} className='text-end'>{r.amount}</td>
</tr>
)
})
}

</tbody>
<tfoot>
<tr className="fw-bold bg-warning">
<td></td>
<td></td>
<td></td>
<td></td>
<td className='text-end'>Total</td>
<td></td>
<td></td>
<td></td>
<td></td>
<td></td>
<td></td>
<td></td>
<td></td>
<td></td>
<td></td>
<td className='text-end'>{this.state.l.amountTotal}</td>
</tr>
</tfoot>
</Table>
</Card.Body>

</Card>
)
}
}