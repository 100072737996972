import React, { useEffect, useState } from 'react'
import { Badge, Button, ButtonGroup, ButtonToolbar, Card, Col, Container, FormControl, InputGroup, Row, Spinner, Table, ToastContainer } from 'react-bootstrap'
import axios from 'axios';
import  * as Icons  from 'react-bootstrap-icons';
import { useLocation,matchPath, useRouteMatch, useParams } from 'react-router-dom'

interface types{
    isload:boolean,
    l:any,

}
export default class punchLog extends React.Component<any,types> {
constructor(props: any) {
    super(props)
    this.state = {l:{logs:[],datesbywords:[]},isload:true}
}

componentDidMount() {
    let q = Object.fromEntries(new URLSearchParams(this.props.location.search))
    console.log(q);
    
    axios.get('/payroll/report/punchLog',{params:q}).then(res=>{
        this.setState({...this.state,l:res.data,isload:false})
    })
}

render(){
return(
<Card className='m-2'>
<ToastContainer />
<Card.Header as="h5" className="">Punch Log
<div className="float-end">
<ButtonToolbar aria-label="Toolbar with Button groups">
<ButtonGroup aria-label="First group" className=" mt-1">
<Spinner animation="grow" className={this.state.isload?'':'d-none'} />

<Button size="sm" variant="outline-success" className={"mx-1 "+this.state.isload?'d-none':''} title="Save [Alt + S]" type="submit" form="form"><Icons.ArrowLeft size="16px" /></Button>
</ButtonGroup>

</ButtonToolbar>

</div>

</Card.Header>

<Card.Body className="d-print-block">
 
<Table striped bordered responsive hover size="sm" className="prn-block">
<thead>
<tr>
<th className='text-center d-print-block'>S.No</th>
<th className='text-start'>Employee Name</th>
<th className='text-end'>Emp Code</th>
{
    this.state.l.datesbywords.map((dw:any,i:any)=>{
        return (<th className='text-center' key={i}>{dw}</th>)
    })

}
</tr>
</thead>
<tbody>
{this.state.l.logs.map((r: any, i:number) => {
return (
<tr key={i}>
<td style={{width:"20px"}} className="text-end">{i + 1}</td>
<td>{r.employee}</td>
<td className="text-end" style={{width:"80px"}}>{r.UserId}</td>
{r.punchLog.map((d:string)=>{return(<td className='text-center'>{d}</td>)})}
</tr>
)
})
}

</tbody>

</Table>
</Card.Body>

</Card>
)
}
}