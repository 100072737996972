import React, { useEffect, useState } from 'react'
import { Badge, Button, ButtonGroup, ButtonToolbar, Card, Col, Container, FormControl, InputGroup, Row, Spinner, Table} from 'react-bootstrap'
import * as Icons from 'react-bootstrap-icons'
import { Link, useRouteMatch, useParams } from 'react-router-dom'
import axios from 'axios';
import $ from 'jquery'
import { toast,ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


export default function EmployeeList() {
    let [l, setl] = useState<any>([])
    let { path, url } = useRouteMatch();
    let params:any = useParams()

    useEffect(()=>{
        axios.get('payroll/employee').then(res=>{
            setl(res.data.data)
        })
    },[])

    return (

<Card className='m-2'>
<ToastContainer
position="top-right"
autoClose={5000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
/>
{/* Same as */}
<ToastContainer />
<Card.Header as="h5" className="d-print-none">Employee <Badge bg="info">list</Badge>

<div className="float-end">  
<ButtonToolbar aria-label="Toolbar with Button groups">
<ButtonGroup aria-label="First group" className=" mt-1">
<Spinner animation="grow" className={'d-none'} />
<Link to="/payroll/employee"><Button size="sm" variant="outline-secondary" className={"mx-1 "} ><Icons.FilePlusFill size="18px" /></Button></Link>
</ButtonGroup>
</ButtonToolbar>
</div>

</Card.Header>

<Card.Body>
<Table striped bordered hover size="sm">
<thead>
<tr>
<th>#</th>
<th>Employee</th>
<th>Code</th>
<th>Salary</th>
<th>Shift</th>
<th>Designation</th>
<th>Action</th>
</tr>
</thead>
<tbody>
{l.map((r: any, i:number) => {
return (
<tr key={i}>
<td style={{width:"20px"}}>{i + 1}</td>
<td>{r.employee}</td>
<td>{r.employeeCode}</td>
<td>{r.salary}</td>
<td>{r.shiftIid}</td>
<td>{r.designationIid}</td>
<td style={{width:"20px"}}><Link to={"/payroll/employee/"+r.id}><Icons.PencilSquare className="text-info ms-3" style={{ cursor: "hand" }} /></Link></td>
</tr>
)
})
}

</tbody>
</Table>
</Card.Body>

</Card>
    )
}
