import React, { useEffect, useState } from 'react'
import { Badge, Button, ButtonGroup, ButtonToolbar, Card, Col, Container, Form, FormControl, InputGroup, Row, Spinner, Table, ToastContainer } from 'react-bootstrap'
import axios from 'axios';
import  * as Icons  from 'react-bootstrap-icons';

export default function PayrollReport() {
    const [v, setv]:any = useState({})
    const [l, setl]:any = useState({})
    const [report, setreport] = useState('')
    const [ec,setec] = useState([])
    useEffect(()=>{
       axios.get('/payroll/employeeCategory').then((r:any)=>{
         setec(r.data.data)
       })
    },[])

    const search=()=>{
        axios.get('/payroll/report/weeklySalary').then(r=>{
            setl(r.data.data)
        })
    }
    return (

<Card className='m-2'>
<ToastContainer />
<Card.Header as="h5" className="d-print-none">Payroll Report <Badge bg="info">{v.action}</Badge>
<div className="float-end">
<ButtonToolbar aria-label="Toolbar with Button groups">
<ButtonGroup aria-label="First group" className=" mt-1">
<Spinner animation="grow" className={v.isload?'':'d-none'} />

<Button size="sm" variant="outline-success" className={"mx-1 "} title="Save [Alt + S]" type="submit" form="form"><Icons.Search size="16px" /></Button>
</ButtonGroup>

</ButtonToolbar>

</div>

</Card.Header>

<Card.Body>


<Form  id='form' action={'/payroll/report/'+report} method='get' >
      <Row>

      <Col md={4}><InputGroup className="mb-1 input-group-sm">
<InputGroup.Text>From</InputGroup.Text>
<FormControl className="fw-bold" type="date" name="from" autoFocus />
</InputGroup></Col>

<Col md={4}><InputGroup className="mb-1 input-group-sm">
<InputGroup.Text>To</InputGroup.Text>
<FormControl className="fw-bold" type="date" name="to" />
</InputGroup></Col>

<Col md={4}><InputGroup className="mb-1 input-group-sm"><InputGroup.Text>Employee Category</InputGroup.Text>
<FormControl as="select" className="fw-bold" name="employeeCategoryId">
  {ec.map((r:any,i:number)=><option value={r.id} key={i}>{r.employeeCategory}</option>)}
  <option value="0">-Empty-</option>
  <option value="">-All-</option>
</FormControl>
</InputGroup></Col>

<fieldset>
    <Form.Group as={Row} className="mb-3">
      <Form.Label as="legend" column sm={2}>
        Report
      </Form.Label>
      <Col sm={10}>
<Form.Check
          type="radio"
          label="Weekly Wages"
          name="report"
          value="weeklyWages"
          onChange={(e)=>setreport(e.target.value)}
          id="test"
        />
        <Form.Check
          type="radio"
          label="Punch Log"
          name="report"
          value="punchLog"
          onChange={(e)=>setreport(e.target.value)}
          id="tes1"
        />
        <Form.Check
          type="radio"
          label="Monthly Salary"
          name="report"
          value="monthlySalary"
          onChange={(e)=>setreport(e.target.value)}
          id="test2"
        />
 </Col>
    </Form.Group>
  </fieldset> 

 
 


</Row>
</Form>

     
  
    
</Card.Body>

</Card>
    )
}
