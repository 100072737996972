import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Link, NavLink } from 'react-router-dom'
import * as Icon from 'react-bootstrap-icons';

function Sidebar() {
    let [menus, setMenus] = useState([])
    let [routes, setRoutes] = useState([])
    let [ismenu, setismenu] = useState(false)
    let [isroute, setisroute] = useState(false)
    let ugRoutes = JSON.parse(localStorage.userGroupRoute)
    let ugMenus = JSON.parse(localStorage.userGroupMenu)

    useEffect(() => {
        function lod() {
            if (!ismenu) {
                console.log('menus')
                setismenu(true)
                if (localStorage.menu) {
                    setMenus(JSON.parse(localStorage.menu))
                } else {
                    axios.get('core/menu').then((e:any) => {
                        localStorage.setItem('menu', JSON.stringify(e.data.data))
                        setMenus(e.data.data)
                    })
                }
            }
            if (!isroute) {
                console.log('route')
                setisroute(true)
                if (localStorage.route) {
                    setRoutes(JSON.parse(localStorage.route))
                } else {
                    axios.get('core/route').then((e:any) => {
                        localStorage.setItem('route', JSON.stringify(e.data.data))
                        setRoutes(e.data.data)
                    })
                }
            }
        }
        lod()
    })

    return (
        <div id="app-sidepanel" className={"app-sidepanel d-print-none "}>
        <div id="sidepanel-drop" className="sidepanel-drop"></div>
        <div className="sidepanel-inner d-flex flex-column">
            <span  id="sidepanel-close" className="sidepanel-close d-xl-none">&times;</span>
            <div className="app-branding">
                <span className="app-logo"><img className=" me-2" src="/asset/image/logo.png" width="180" alt="logo" /></span>

            </div>
            <nav id="app-nav-main" className="app-nav app-nav-main flex-grow-1">
                <ul className="app-menu list-unstyled accordion" id="menu-accordion">

                    <li className="nav-item nav-link active"><Link to="/dashboard"><span className="nav-icon"><Icon.Speedometer /></span><span className="nav-link-text">Dashboard</span></Link></li>
                    {
                        routes.map((r: any, i: any) => {
                            let ugRoute = ugRoutes.filter((ugrr:any)=>ugrr.addPermission === true && ugrr.routeUid===r.uid)[0]||{}
                            if (ugRoute.id){
                            return (
                                <li className="submenu-item" key={i}><NavLink activeClassName='active' className="submenu-link" to={`/${r.uiRoute}`}  >{r.route}</NavLink></li>
                                //<li className="submenu-item" key={i}><Link to={`/${r.apiRoute}`} className="submenu-link">{r.route}</Link></li>
                            )
                            }else{
                                return('')
                            }
                        })
                    }

                    <li className="nav-item has-submenu 1 ">

                        <span className="nav-link submenu-toggle" role="button" data-bs-toggle="collapse" data-bs-target="#submenu-2" aria-expanded="false" aria-controls="submenu-2">
                            <span className="nav-icon"><Icon.Collection /></span>
                            <span className="nav-link-text">Master</span>
                            <span className="submenu-arrow"><Icon.ChevronDown /></span>
                        </span>
                        <div id="submenu-2" className="collapse submenu submenu-2" data-bs-parent="#menu-accordion">
                            <ul className="submenu-list list-unstyled">
                                {
                                    
                                    menus.map((r: any, i: any) => {
                                        let ugMenu = ugMenus.filter((ugmr:any)=>ugmr.addPermission === true && ugmr.menuUid===r.uid)[0]||{}
                                        if (ugMenu.id){
    
                                        return (
                                            <li className="submenu-item" key={i}><NavLink  className="submenu-link" to={`/${r.addon}/${r.route}`} >{r.menu}</NavLink></li>
                                            // <li className="submenu-item" key={i}><Link to={`/${r.addon.addon}/${r.route}`} className="submenu-link">{r.menu}</Link></li>
                                        )
                                        }else{
                                            return ('')
                                        }
                                    })
                                }
                            </ul>
                        </div>
                    </li>

                    <li className="nav-item has-submenu 1 ">

                        <span className="nav-link submenu-toggle" data-bs-toggle="collapse" data-bs-target="#submenu-3" aria-expanded="false" aria-controls="submenu-2">
                            <span className="nav-icon"><Icon.GraphUp /></span>
                            <span className="nav-link-text">Report</span>
                            <span className="submenu-arrow">
                                <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-chevron-down" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z" />
                                </svg>
                            </span>
                        </span>
                        <div id="submenu-3" className="collapse submenu submenu-3" data-bs-parent="#menu-accordion">
                            <ul className="submenu-list list-unstyled">
                                <li className="submenu-item"><Link to="/billing/report/accounts_daybook" className="submenu-link">Accounts Daybook</Link></li>
                            </ul>
                        </div>
                    </li>

                    <li className="nav-item has-submenu submenu-4 ">
                        <span className="nav-link submenu-toggle" data-bs-toggle="collapse" data-bs-target="#submenu-4" aria-expanded="false" aria-controls="submenu-4">
                            <span className="nav-icon"><Icon.Tools /></span>
                            <span className="nav-link-text">Tools</span>
                            <span className="submenu-arrow"><Icon.ChevronDown /></span>
                        </span>
                        <div id="submenu-4" className="collapse submenu submenu-4" data-bs-parent="#menu-accordion-4">
                            <ul className="submenu-list list-unstyled">
                                <li className="submenu-item"><Link to="/core/user" className="submenu-link">User</Link></li>
                                <li className="submenu-item"><Link to="/core/userGroup" className="submenu-link">User Group</Link></li>
                            </ul>
                        </div>
                    </li>

                </ul>
            </nav>
        

        </div>
    </div>
    )
}
export default Sidebar