import { Badge, Button, ButtonGroup, ButtonToolbar, Card, Col, Container, FormControl, InputGroup, Row, Spinner, Table, } from 'react-bootstrap'
import { Redirect } from "react-router-dom";
import * as Icons from 'react-bootstrap-icons';
import React from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';

interface TypeUserGroup {
action?: String,
id?: String,
f: {
userGroup: string,
id: String,
userGroupRoute:any,
userGroupMenu:any
},
routes:[][],
menus:[][],
l: [], 
islist:Boolean,
issave:Boolean,
isview:Boolean,
isdelete:Boolean
}



export default class UserGroup extends React.Component<any,TypeUserGroup> {
constructor(props: any) {
super(props)

this.newstate = {
    l: [], 
    f: { 
    userGroup: '',
    id:'',
    userGroupRoute:[],
    userGroupMenu:[]
    
    },
    routes:[],
    menus:[],
    islist:false,
    issave:false,
    isview:false,
    isdelete:false 
    }



this.state = this.newstate

}
newstate:TypeUserGroup
routes = JSON.parse(localStorage.route)
menus = JSON.parse(localStorage.menu)

componentDidUpdate(prevProps: any, prevState: any) {
// console.log('prevProp',prevProps);
// console.log('prevStat',prevState);
// console.log('curact',this.state.action);
//console.log('didupdate');
//console.log(this.state);


}

componentWillUnmount() {
//console.log('comwillunmount');
}

componentDidMount() {
console.log('didmount');

// axios.get('core/route').then(r=>this.setState({routes:r.data.data}))
// axios.get('core/menu').then(r=>this.setState({menus:r.data.data}))

if (this.props.match.path === '/core/userGroup/print/:id') {
this.preview(this.props.match.params.id)
} else if (this.props.match.path === '/core/userGroup/:id') {
this.view(this.props.match.params.id)
} else if (this.props.match.path === '/core/userGroup/list') {
this.list()
} else {
this.new()
}
}

insert(){
console.log('insert');
this.setState({issave:true})
axios.post('core/userGroup', this.state.f)
.then(r => {
toast.warn("Inserted Successfully", {position: "bottom-center",autoClose: 5000,hideProgressBar: false,closeOnClick: true,pauseOnHover: true,draggable: true,progress: undefined,})
this.setState({issave:false})
this.new()
})
.catch(e => {
console.log(e);
this.setState({issave:false})
toast.error(e.response?JSON.stringify(e.response.data):e.message, {position: "bottom-center",autoClose: 5000,hideProgressBar: false,closeOnClick: true,pauseOnHover: true,draggable: true,progress: undefined,})
})
}

update(){    
this.setState({issave:true})
axios.put('core/userGroup/'+this.state.f.id, this.state.f)
.then(r => {
toast.warn("Updated Successfully", {position: "bottom-center",autoClose: 5000,hideProgressBar: false,closeOnClick: true,pauseOnHover: true,draggable: true,progress: undefined,})
this.setState({issave:false})
this.new()
})
.catch(e => {
this.setState({issave:false})
toast.error(e.response?JSON.stringify(e.response.data):e.message, {position: "bottom-center",autoClose: 5000,hideProgressBar: false,closeOnClick: true,pauseOnHover: true,draggable: true,progress: undefined,})
})
}

save = () =>this.state.f.id?this.update():this.insert()



list = () => {
this.setState({ action: 'list',islist:true })
axios.get('core/userGroup').then((r:any) => {
this.setState({ l: r.data.data,islist:false })
})
.catch(e => {
this.setState({islist:false })
console.log('error');

})
}

view = (id: String) => {
//this.setState({ action: 'edit',id:id,isview:true })
axios.get('core/userGroup/' + id).then(res => {
    let ug:any=res.data

let userGroupRoute:any=[]
this.routes.map((r:any)=>{
    userGroupRoute.push({
        routeId:r.id,
        //route:r.route,
        addPermission:(ug.userGroupRoute.some((ugr:any)=>ugr.routeId===r.id&&ugr.addPermission===true)),
        viewPermission:(ug.userGroupRoute.some((ugr:any)=>ugr.routeId===r.id&&ugr.viewPermission===true)),
        deletePermission:(ug.userGroupRoute.some((ugr:any)=>ugr.routeId===r.id&&ugr.deletePermission===true)),
        editPermission:(ug.userGroupRoute.some((ugr:any)=>ugr.routeId===r.id&&ugr.editPermission===true)),
    })
})

let userGroupMenu:any =[]
this.menus.map((r:any)=>{
    userGroupMenu.push({
        menuId:r.id,
        //menu:r.route,
        addPermission:(ug.userGroupMenu.some((ugm:any)=>ugm.menuId===r.id&&ugm.addPermission===true)),
        viewPermission:(ug.userGroupMenu.some((ugm:any)=>ugm.menuId===r.id&&ugm.viewPermission===true)),
        deletePermission:(ug.userGroupMenu.some((ugm:any)=>ugm.menuId===r.id&&ugm.deletePermission===true)),
        editPermission:(ug.userGroupMenu.some((ugm:any)=>ugm.menuId===r.id&&ugm.editPermission===true)),
    })
})

console.log(userGroupRoute);

this.setState({...this.newstate,
    action:'edit',
    isview:false,
    f:{...this.state.f,
        userGroup:res.data.userGroup,
        id:res.data.id,
        userGroupMenu:userGroupMenu,
        userGroupRoute:userGroupRoute
    }
})

}).catch(e=>{
this.setState({isview:false })
toast.error(e.response?JSON.stringify(e.response.data):e.message, {position: "bottom-center",autoClose: 5000,hideProgressBar: false,closeOnClick: true,pauseOnHover: true,draggable: true,progress: undefined,})
})
}

preview = (id: String) => {
this.setState({ ...this.state, action: 'preview' })
}
duplicate=()=>{
this.setState({f:{...this.state.f,id:''}})
toast.info('Duplicated', {position: "bottom-center",autoClose: 5000,hideProgressBar: false,closeOnClick: true,pauseOnHover: true,draggable: true,progress: undefined,})
}
print = () => {
console.log('print');
window.print()
}

new = () => {
console.log('new');
let userGroupRoute:any=[]

this.routes.map((r:any)=>{
    userGroupRoute.push({
        routeId:r.id,
        route:r.route,
        addPermission:false,
        viewPermission:false,
        deletePermission:false,
        editPermission:false
    })
    
})

// this.setState({f:{...this.state.f,userGroupRoute:userGroupRoute}})
let userGroupMenu:any =[]
this.menus.map((r:any)=>{
    userGroupMenu.push({
        menuId:r.id,
        menu:r.route,
        addPermission:false,
        viewPermission:false,
        deletePermission:false,
        editPermission:false
    })
})

console.log(userGroupMenu);

// this.setState({f:{...this.state.f,userGroupMenu:userGroupMenu}})

this.setState({...this.newstate,action:'new',f:{...this.state.f,userGroupMenu:userGroupMenu,userGroupRoute:userGroupRoute}})
//console.log(this.state)
}

delete = (id:String) => { 
this.setState({isdelete:true})
axios.delete('core/userGroup/'+id).then(r=>{
toast.info('Deleted Successfully', {position: "bottom-center",autoClose: 5000,hideProgressBar: false,closeOnClick: true,pauseOnHover: true,draggable: true,progress: undefined,})
this.setState({isdelete:false})
this.list()
}).catch(e=>{
this.setState({isdelete:false})
toast.error(e.response.data?JSON.stringify(e.response.data):e.message, {position: "bottom-center",autoClose: 5000,hideProgressBar: false,closeOnClick: true,pauseOnHover: true,draggable: true,progress: undefined,})
})
}
setd = (e: any) => {
this.setState({ ...this.state, f: {...this.state.f, [e.target.id]: e.target.value } })
}

render() {
return (
<Container className="mt-2">
<ToastContainer />
{this.state.action === 'list' ? (<Redirect to="/core/userGroup/list" push />) :
this.state.action === 'edit' ? (<Redirect to={`/core/userGroup/${this.state.f.id}`} push />) :
this.state.action === 'preview' ? (<Redirect to={`/core/userGroup/print/${this.state.f.id}`} push />) :
this.state.action === 'new' ? (<Redirect to="/core/userGroup" push />) :
''
}

<Card>
<Card.Header as="h5" className="d-print-none"><Icons.CartPlusFill /> User Group <Badge bg="info">{this.state.action}</Badge>
<div className="float-end">
<ButtonToolbar aria-label="Toolbar with Button groups">
<ButtonGroup aria-label="First group" className=" mt-1">
<Spinner animation="grow" className={this.state.islist || this.state.issave || this.state.isview || this.state.isdelete?'':'d-none'} />
<Button size="sm" variant="outline-primary" className={"mx-1 "+ (this.state.action === 'list' || this.state.islist ? 'd-none' : '')} onClick={this.list}><Icons.ListUl size="18px" /></Button>
<Button size="sm" variant="outline-secondary" className={"mx-1 "+ (this.state.action === 'new' ? 'd-none' : '')} onClick={this.new}><Icons.FilePlusFill size="18px" /></Button>
<Button size="sm" variant="outline-success" className={"mx-1 " + (this.state.action === 'new' || this.state.action === 'edit' ? '' : 'd-none ') + (this.state.issave?'d-none':'')} title="Save [Alt + S]" onClick={this.save}><Icons.Save2Fill size="16px" /></Button>

<div className="dropdown">
<Button size="sm" data-bs-toggle="dropdown" variant="outline-dark" className="mx-1"><Icons.ThreeDotsVertical size="18px" /></Button>
<ul className="dropdown-menu" aria-labelledby="user-dropdown-toggle">
<li><button className={"dropdown-item "+ (this.state.action==='edit'?'':'d-none')} onClick={this.duplicate} ><Icons.Files/> Duplicate</button></li>
<li><button className={"dropdown-item "+ (this.state.action==='edit'?'':'d-none')+(this.state.isdelete?' d-none':'')}  onClick={()=>this.delete(this.state.f.id)} ><Icons.EraserFill/> Delete</button></li>
<li><hr className="dropdown-divider" /></li>
<li><button className={"dropdown-item "+ (this.state.action==='list'?'':'d-none')}  >Download CSV</button></li>
</ul>
</div>
</ButtonGroup>

</ButtonToolbar>

</div>

</Card.Header>

<Card.Body className={this.state.action === 'new' || this.state.action === 'edit' ? '' : "d-none"}>

<Row>
<Col md={4}><InputGroup className="mb-1 input-group-sm"><InputGroup.Text>User Group</InputGroup.Text><FormControl className="fw-bold" id="userGroup" onChange={this.setd} value={this.state.f.userGroup} autoFocus /></InputGroup></Col>
</Row>
<Row>
<Table striped bordered hover size="sm">
<thead>
<tr>
<th className='text-center align-middle'>#</th>
<th className='text-center align-middle'>User Group</th>
<th className='text-center align-middle'>Add<input type="checkbox" /></th>
<th className='text-center align-middle'>Edit<input type="checkbox" /></th>
<th className='text-center align-middle'>View<input type="checkbox" /></th>
<th className='text-center align-middle'>Delete<input type="checkbox" /></th>
</tr>
</thead>
<tbody>
<tr className="bg-warning">
<th className='text-center align-middle'></th>
<th className='text-center align-middle'>Vouchers</th>
<th className='text-center align-middle'></th>
<th className='text-center align-middle'></th>
<th className='text-center align-middle'></th>
<th className='text-center align-middle'></th>
</tr>
{
this.state.f.userGroupRoute.map((r: any, i:number) => {
    let route_info = this.routes.filter((route:any)=>route.id===r.routeId)[0]
return (
<tr key={i}>
<td style={{width:"20px"}}>{i + 1}</td>
<td>{route_info.route}</td>
<td style={{width:"20px"}} className='text-center'><input  type="checkbox" checked={this.state.f.userGroupRoute[i].addPermission} onChange={(e)=>{
    let a:any = this.state.f.userGroupRoute
    a[i].addPermission = e.target.checked
    
    this.setState({f:{...this.state.f,userGroupRoute:a}})

}}  /></td>
<td style={{width:"20px"}} className='text-center'></td>
<td style={{width:"20px"}} className='text-center'></td>
<td style={{width:"20px"}} className='text-center'></td>
</tr>
)
})
}

<tr className="bg-warning">
<th className='text-center align-middle'></th>
<th className='text-center align-middle'>Menu</th>
<th className='text-center align-middle'></th>
<th className='text-center align-middle'></th>
<th className='text-center align-middle'></th>
<th className='text-center align-middle'></th>
</tr>

{
this.state.f.userGroupMenu.map((r: any, i:number) => {
    let m = this.menus.filter((m:any)=>m.id===r.menuId)[0]
return (
<tr key={i}>
<td style={{width:"20px"}}>{i + 1}</td>
<td>{m.menu}</td>
<td style={{width:"20px"}} className='text-center'><input type="checkbox" checked={this.state.f.userGroupMenu[i].addPermission} onChange={(e)=>{
    let a:any = this.state.f.userGroupMenu
    a[i].addPermission = e.target.checked
    
    this.setState({f:{...this.state.f,userGroupMenu:a}})

}} /></td>
<td style={{width:"20px"}} className='text-center'></td>
<td style={{width:"20px"}} className='text-center'></td>
<td style={{width:"20px"}} className='text-center'></td>
</tr>
)
})
}

</tbody>
</Table>
</Row>
</Card.Body>
<Card.Body className={this.state.action === 'list' ? '' : "d-none"}>
<Table striped bordered hover size="sm">
<thead>
<tr>
<th>#</th>
<th>User Group</th>
<th>Action</th>
</tr>
</thead>
<tbody>
{this.state.l.map((r: any, i:number) => {
return (
<tr key={i}>
<td style={{width:"20px"}}>{i + 1}</td>
<td>{r.userGroup}</td>
<td style={{width:"20px"}}><Icons.PencilSquare className="text-warning ms-3" style={{ cursor: "hand" }} onClick={() => this.view(r.id)} /></td>
</tr>
)
})
}

</tbody>
</Table>

</Card.Body>
</Card>
</Container>
)
}
}