import React, { useEffect, useState } from 'react'
import { Badge, Button, ButtonGroup, ButtonToolbar, Card, Col, Container, FormControl, InputGroup, Row, Spinner, Table, ToastContainer } from 'react-bootstrap'
import * as Icons from 'react-bootstrap-icons'
import { Link, useRouteMatch, useParams } from 'react-router-dom'
import axios from 'axios';
import 'jquery-ui';

type GetIconProps = {
    icon: keyof typeof Icons;
  }

export default function Menu() {
    let finit:any={
        menu:'',
        icons:[],
    }
    
    
    let [f, setf] = useState<any>(finit)
    const [v, setv] = useState<any>(finit)
    const [ico, setico] = useState<GetIconProps>({icon:"Alarm"})
    const [addons, setaddons] = useState([])
    const [menutypes,setmenutypes] = useState<any>([
        'master',
        'report',
        'widget',
        'tools',
        'core'
      ])
    let { path, url } = useRouteMatch();
    let params:any = useParams()
    let test:Icons.Icon


    // function getIcon({icon}: GetIconProps) {
    //     const TagName = Icons[icon];
    //     return <TagName />;
    //   }
    useEffect(()=>{

        // $('*[name=menuType]').autocomplete(menutypes)
        axios.get('/core/addon').then(r=>{
            setaddons(r.data.data)
        })
        //const {[test]:Icon} = Icons        
        
        // let IconObject:any = []
        // Object.keys(Icons).map(r=>{
        //     IconObject.push({icon:r})
        // })
        // setv({icons:IconObject});        
        if (path === '/core/menu/:uid') {
            simsview(params.uid)
        }else if (path === '/core/menu/list') {
            simslist()
        }else{
            simsnew()
        }
    },[])

   let lookuplist = [
        { name: "Dave", id: 4780127, capital: "Montgomery", region: "South" },
        { name: "Jessie", id: 710249, capital: "Juneau", region: "West" },
        { name: "Steven", id: 6392307, capital: "Phoenix", region: "West" },
      ];

    const simsnew=()=>{
        setf({...finit,action:'new'})
    }

    const simsview=(uid:string)=>{
        axios.get('core/menu/'+uid).then((res:any)=>{
            setf(res.data)            
        })
    }

    const simslist=()=>{

    }

    const simsdelete=(uid:string)=>{

    }
    const simssave=(data:any)=>{
        console.log(data);

    }

    const simsupdate=()=>{
        alert('update')
    }

    const simsinsert=()=>{
        alert('create')
    }

    const handleChange = (value:any) => {
        console.log(value)
    }
    
    const options = [
        { value: 'chocolate', label: 'Chocolate' },
        { value: 'strawberry', label: 'Strawberry' },
        { value: 'vanilla', label: 'Vanilla' }
      ]
    return (

<Card className='m-2'>
<ToastContainer />
<Card.Header as="h5" className="d-print-none">Menu <Badge bg="info">{v.action}</Badge>
<div className="float-end">
<ButtonToolbar aria-label="Toolbar with Button groups">
<ButtonGroup aria-label="First group" className=" mt-1">
<Spinner animation="grow" className={v.issave || v.isview || v.isdelete?'':'d-none'} />

<Link to="/core/menu/list"><Button size="sm" variant="outline-primary" className={"mx-1 "}><Icons.ListUl size="18px" /></Button></Link>
<Link to="/core/menu"><Button size="sm" variant="outline-secondary" className={"mx-1 "+ (v.action === 'new' ? 'd-none' : '')} onClick={simsnew}><Icons.FilePlusFill size="18px" /></Button></Link>

<Button size="sm" variant="outline-success" className={"mx-1 "} title="Save [Alt + S]" type="submit" form="form"><Icons.Save2Fill size="16px" /></Button>

<div className="dropdown">
<Button size="sm" data-bs-toggle="dropdown" variant="outline-dark" className="mx-1"><Icons.ThreeDotsVertical size="18px" /></Button>

<ul className="dropdown-menu" aria-labelledby="user-dropdown-toggle">
<li><button className={"dropdown-item "+ (v.action==='edit'?'':'d-none')+(v.isdelete?' d-none':'')}  onClick={()=>simsdelete(f.uid)} ><Icons.EraserFill/> Delete</button></li>
<li><hr className="dropdown-divider" /></li>
<li><button className={"dropdown-item "+ (v.action==='list'?'':'d-none')}  >Download CSV</button></li>
</ul>
</div>
</ButtonGroup>

</ButtonToolbar>

</div>

</Card.Header>

<Card.Body>


<form  id='form'>
      <input type={'hidden'} defaultValue={f.uid} />
      <Row>

<Col md={4}><InputGroup className="mb-1 input-group-sm"><InputGroup.Text>Menu</InputGroup.Text><FormControl className="fw-bold"  defaultValue={f.menu} autoFocus /></InputGroup></Col>

<Col md={4}><InputGroup className="mb-1 input-group-sm"><InputGroup.Text>Menu Type</InputGroup.Text>
<FormControl className="fw-bold"  name="menuType" />
{/* <Typeahead
    id="input-account"
    options={menutypes}
    className='fw-bold'
      defaultSelected={menutypes.filter((x:any) => x === 'core')}
      autoFocus
  /> */}

</InputGroup></Col>

<Col md={4}><InputGroup className="mb-1 input-group-sm"><InputGroup.Text>Icon</InputGroup.Text>

{/* <Typeahead
    id="icon" 
    onChange={(selected:any)=>{
        //console.log(selected[0].icon)
        setico({icon:selected.length>0?selected[0].icon:'Alarm'})
    }}
    options={v.icons}
    className='fw-bold'
    filterBy={["icon"]}
    labelKey={(list:any) => `${list.icon}`}
  /> */}
<InputGroup.Text>{'s'}</InputGroup.Text>
</InputGroup></Col>


<Col md={4}><InputGroup className="mb-1 input-group-sm"><InputGroup.Text>Addon</InputGroup.Text>

{/* <Typeahead
    id="addon" size='small'
    onChange={handleChange}
    options={addons}
    placeholder="Choose a addon..."
    //  defaultSelected={lookuplist.filter(x => x.id === 710249)}
    filterBy={["addon"]}
    labelKey={(lookuplist:any) => `${lookuplist.addon}`}
  /> */}
</InputGroup></Col>

<Col md={4}><InputGroup className="mb-1 input-group-sm"><InputGroup.Text>Route</InputGroup.Text><FormControl className="fw-bold"  defaultValue={f.route} /></InputGroup></Col>

<Col md={4}><InputGroup className="mb-1 input-group-sm"><InputGroup.Text>Sort Order</InputGroup.Text><FormControl className="fw-bold" defaultValue={f.sortOrder} /></InputGroup></Col>
</Row>
</form>

</Card.Body>

</Card>
    )
}
