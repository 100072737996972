import React, { useEffect, useState } from 'react'
import { Badge, Button, ButtonGroup, ButtonToolbar, Card, Col, Container, Form, FormControl, InputGroup, Row, Spinner, Table} from 'react-bootstrap'
import * as Icons from 'react-bootstrap-icons'
import { Link, useRouteMatch, useParams } from 'react-router-dom'
import axios from 'axios';
import $ from 'jquery'
import { toast,ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


export default function Employee() {
    let finit:any={
        menu:'',
        icons:[],
    }
    
    
    let [f, setf] = useState<any>({})
    let [l, setl] = useState<any>({})
    const [v, setv] = useState<any>({})
    const [addons, setaddons] = useState([])
    const [menutypes,setmenutypes] = useState<any>([
        'master',
        'report',
        'widget',
        'tools',
        'core',
        'menu'
      ])
    let { path, url } = useRouteMatch();
    let params:any = useParams()
    let test:Icons.Icon
    const [ec,setec] = useState([])
    const [s,sets] = useState([])
    useEffect(()=>{
        if (path === '/payroll/employee/:id') {
            simsview(params.id)
        }else if (path === '/payroll/employee/list') {
            simslist()
        }else{
            simsnew()
        }

        axios.get('/payroll/employeeCategory').then((r:any)=>{
            setec(r.data.data)
          })
          axios.get('/payroll/shift').then((r:any)=>{
            sets(r.data.data)
          })

        },[])

    const simsnew=()=>{
        setf({...finit,action:'new'})
    }

    const simsview=(id:number)=>{
        setv({...v,isedit:true})
        axios.get('payroll/employee/'+id).then((res:any)=>{
            res.data['isedit'] = false
            setv(res.data)
        }).catch(e=>{
            setv({...v,isedit:false})
        })
    }

    const simslist=()=>{
        axios.get('core/menu').then(res=>{
            setl(res.data.data)
        })
    }

    const simsdelete=(id:number)=>{
        setv({...v,isdelete:true})

        axios.delete('payroll/employee/'+v.id).then(res=>{
            setv({...v,isdelete:false})
            simsnew()
        })
    }

    const simssave=()=>{
        v.id?simsupdate():simsinsert()
    }

    const simsupdate=()=>{
        axios.put('payroll/employee/'+v.id,v).then((res:any)=>{
            console.log(res);
            toast.success('Updated',{
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                });
        })
    }

    const simsinsert=()=>{
        axios.post('payroll/employee',v).then((res:any)=>{
            console.log(res);
            toast.success('Saved',{
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                });
        })
    }

    const handleChange = (value:any) => {
        console.log(value)
    }
    
    const options = [
        { value: 'chocolate', label: 'Chocolate' },
        { value: 'strawberry', label: 'Strawberry' },
        { value: 'vanilla', label: 'Vanilla' }
      ]
    return (

<Card className='m-2'>
<ToastContainer
position="top-right"
autoClose={5000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
/>
{/* Same as */}
<ToastContainer />
<Card.Header as="h5" className="d-print-none">Employee <Badge bg="info">{v.action}</Badge>
<div className="float-end">
<ButtonToolbar aria-label="Toolbar with Button groups">
<ButtonGroup aria-label="First group" className=" mt-1">
<Spinner animation="grow" className={v.issave || v.isview || v.isdelete?'':'d-none'} />

<Link to="/payroll/employee/list"><Button size="sm" variant="outline-primary" className={"mx-1 "}><Icons.ListUl size="18px" /></Button></Link>
<Link to="/payroll/employee"><Button size="sm" variant="outline-secondary" className={"mx-1 "+ (v.action === 'new' ? 'd-none' : '')} onClick={simsnew}><Icons.FilePlusFill size="18px" /></Button></Link>

<Button size="sm" variant="outline-success" className={"mx-1 "} title="Save [Alt + S]" onClick={simssave}><Icons.Save2Fill size="16px" /></Button>

<div className="dropdown">
<Button size="sm" data-bs-toggle="dropdown" variant="outline-dark" className="mx-1"><Icons.ThreeDotsVertical size="18px" /></Button>

<ul className="dropdown-menu" aria-labelledby="user-dropdown-toggle">
<li><button className={"dropdown-item "+ (v.action==='edit'?'':'d-none')+(v.isdelete?' d-none':'')}  onClick={()=>simsdelete(f.id)} ><Icons.EraserFill/> Delete</button></li>
<li><hr className="dropdown-divider" /></li>
<li><button className={"dropdown-item "+ (v.action==='list'?'':'d-none')}  >Download CSV</button></li>
</ul>
</div>
</ButtonGroup>

</ButtonToolbar>

</div>

</Card.Header>

<Card.Body>


<form  id='form'>
      <input type={'hidden'} defaultValue={f.id} />
      <Row>

<Col md={4}><InputGroup className="mb-1 input-group-sm"><InputGroup.Text>Employee Name</InputGroup.Text><FormControl className="fw-bold" onChange={(e:any)=>setv({...v,employee:e.currentTarget.value})}  defaultValue={v.employee} autoFocus /></InputGroup></Col>
<Col md={4}><InputGroup className="mb-1 input-group-sm"><InputGroup.Text>Shift</InputGroup.Text>
<FormControl as="select" className="fw-bold" onChange={(e:any)=>setv({...v,shiftId:e.currentTarget.value})}>
  {s.map((r:any,i:number)=><option value={r.id} selected={r.id==v.shiftId?true:false}  key={i}>{r.shift}</option>)}
</FormControl>
</InputGroup></Col><Col md={4}><InputGroup className="mb-1 input-group-sm"><InputGroup.Text>Salary</InputGroup.Text><FormControl type="number" className="fw-bold" onChange={(e:any)=>setv({...v,salary:e.currentTarget.value})}  defaultValue={v.salary} autoFocus /></InputGroup></Col>


<Col md={4}><InputGroup className="mb-1 input-group-sm"><InputGroup.Text>Employee Code</InputGroup.Text>
<FormControl className="fw-bold"  defaultValue={v.employeeCode} onChange={(e:any)=>setv({...v,employeeCode:e.currentTarget.value})}  />
</InputGroup></Col>

<Col md={4}><InputGroup className="mb-1 input-group-sm"><InputGroup.Text>Employee Category</InputGroup.Text>
<FormControl as="select" className="fw-bold" onChange={(e:any)=>setv({...v,employeeCategoryId:e.currentTarget.value})}>
  {ec.map((r:any,i:number)=><option value={r.id} selected={r.id==v.employeeCategoryId?true:false}  key={i}>{r.employeeCategory}</option>)}
</FormControl>
</InputGroup></Col>
</Row>
</form>

</Card.Body>

</Card>
    )
}
