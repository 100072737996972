import React, { useEffect, useState } from 'react'
import { Badge, Button, ButtonGroup, ButtonToolbar, Card, Col, Container, FormControl, InputGroup, Row, Spinner, Table} from 'react-bootstrap'
import * as Icons from 'react-bootstrap-icons'
import { Link, useRouteMatch, useParams } from 'react-router-dom'
import axios from 'axios';
import $ from 'jquery'
import { toast,ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


export default function DeviceLog() {
    let finit:any={
        menu:'',
        icons:[],
    }
    
    
    let [f, setf] = useState<any>({})
    const [v, setv] = useState<any>({})
    const [addons, setaddons] = useState([])
    const [menutypes,setmenutypes] = useState<any>([
        'master',
        'report',
        'widget',
        'tools',
        'core',
        'menu'
      ])
    let { path, url } = useRouteMatch();
    let params:any = useParams()
    let test:Icons.Icon


    // function getIcon({icon}: GetIconProps) {
    //     const TagName = Icons[icon];
    //     return <TagName />;
    //   }
    useEffect(()=>{

        // $('*[name=menuType]').autocomplete(menutypes)
        //const {[test]:Icon} = Icons        
        
        // let IconObject:any = []
        // Object.keys(Icons).map(r=>{
        //     IconObject.push({icon:r})
        // })
        // setv({icons:IconObject});
        if (path === '/core/menu/:uid') {
            simsview(params.uid)
        }else if (path === '/core/menu/list') {
            simslist()
        }else{
            simsnew()
        }
    },[])

   let lookuplist = [
        { name: "Dave", id: 4780127, capital: "Montgomery", region: "South" },
        { name: "Jessie", id: 710249, capital: "Juneau", region: "West" },
        { name: "Steven", id: 6392307, capital: "Phoenix", region: "West" },
      ];

    const simsnew=()=>{
        setf({...finit,action:'new'})
    }

    const simsview=(uid:string)=>{
        axios.get('core/menu/'+uid).then((res:any)=>{
            setf(res.data)
        })
    }

    const simslist=()=>{

    }

    const simsdelete=(uid:string)=>{

    }
    const simssave=()=>{
        v.id?simsupdate():simsinsert()
    }

    const simsupdate=()=>{
        alert('update')
    }

    const simsinsert=()=>{
        axios.post('payroll/DeviceLogs',v).then((res:any)=>{
            console.log(res);
            toast.success('Success',{
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                });
        })
    }

    const handleChange = (value:any) => {
        console.log(value)
    }
    
    const options = [
        { value: 'chocolate', label: 'Chocolate' },
        { value: 'strawberry', label: 'Strawberry' },
        { value: 'vanilla', label: 'Vanilla' }
      ]
    return (

<Card className='m-2'>
<ToastContainer
position="top-right"
autoClose={5000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
/>
{/* Same as */}
<ToastContainer />
<Card.Header as="h5" className="d-print-none">Device Log <Badge bg="info">{v.action}</Badge>
<div className="float-end">
<ButtonToolbar aria-label="Toolbar with Button groups">
<ButtonGroup aria-label="First group" className=" mt-1">
<Spinner animation="grow" className={v.issave || v.isview || v.isdelete?'':'d-none'} />

<Link to="/core/menu/list"><Button size="sm" variant="outline-primary" className={"mx-1 "}><Icons.ListUl size="18px" /></Button></Link>
<Link to="/core/menu"><Button size="sm" variant="outline-secondary" className={"mx-1 "+ (v.action === 'new' ? 'd-none' : '')} onClick={simsnew}><Icons.FilePlusFill size="18px" /></Button></Link>

<Button size="sm" variant="outline-success" className={"mx-1 "} title="Save [Alt + S]" onClick={simssave}><Icons.Save2Fill size="16px" /></Button>

<div className="dropdown">
<Button size="sm" data-bs-toggle="dropdown" variant="outline-dark" className="mx-1"><Icons.ThreeDotsVertical size="18px" /></Button>

<ul className="dropdown-menu" aria-labelledby="user-dropdown-toggle">
<li><button className={"dropdown-item "+ (v.action==='edit'?'':'d-none')+(v.isdelete?' d-none':'')}  onClick={()=>simsdelete(f.uid)} ><Icons.EraserFill/> Delete</button></li>
<li><hr className="dropdown-divider" /></li>
<li><button className={"dropdown-item "+ (v.action==='list'?'':'d-none')}  >Download CSV</button></li>
</ul>
</div>
</ButtonGroup>

</ButtonToolbar>

</div>

</Card.Header>

<Card.Body>


<form  id='form'>
      <input type={'hidden'} defaultValue={f.uid} />
      <Row>

<Col md={4}><InputGroup className="mb-1 input-group-sm"><InputGroup.Text>Log</InputGroup.Text><FormControl className="fw-bold" type="datetime-local" onChange={(e:any)=>setv({...v,LogDate:e.currentTarget.value})}  defaultValue={f.LogDate} name="LogDate" autoFocus /></InputGroup></Col>

<Col md={4}><InputGroup className="mb-1 input-group-sm"><InputGroup.Text>Employee Code</InputGroup.Text>
<FormControl className="fw-bold"  onChange={(e:any)=>setv({...v,UserId:e.currentTarget.value})}  />
</InputGroup></Col>
</Row>
</form>

</Card.Body>

</Card>
    )
}
