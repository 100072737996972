import Sidebar from './Sidebar';


function Topbar(props: any) {
    let userInfo = JSON.parse(localStorage.user)

    function logout() {
        localStorage.removeItem('token')
        localStorage.removeItem('user')
        localStorage.removeItem('menu')
        localStorage.removeItem('route')
        localStorage.removeItem('companyInfo')
        localStorage.removeItem('userGroupMenu')
        localStorage.removeItem('userGroupRoute')
        window.location.reload();
    }

    
    return (

        <div className="app-header fixed-top d-print-none">
            <div className="app-header-inner">
                <div className="container-fluid py-2">
                    <div className="app-header-content">
                        <div className="row justify-content-between align-items-center">

                            <div className="col-auto">
                                <span id="sidepanel-toggler" className="sidepanel-toggler d-inline-block ">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" role="img"><title>Menu</title><path stroke="currentColor" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2" d="M4 7h22M4 15h22M4 23h22"></path></svg>
                                </span>
                            </div>
                            <div className="search-mobile-trigger d-sm-none col">
                                <i className="search-mobile-trigger-icon fas fa-search"></i>
                            </div>
                            <div className="app-search-box col">
                                <form className="app-search-form text-center">
                                    CPIN:<b>149</b> |
                                    Licensed to: <b>Company</b> |
                                    A/c Year: <b>2021-2022</b>
                                </form>
                            </div>

                            <div className="app-utilities col-auto">
                                <div className="app-utility-item app-notifications-dropdown dropdown">
                                    <span className="dropdown-toggle no-toggle-arrow" id="notifications-dropdown-toggle" data-bs-toggle="dropdown" role="button" aria-expanded="false" title="Notifications">

                                        <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-bell icon" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2z" />
                                            <path fillRule="evenodd" d="M8 1.918l-.797.161A4.002 4.002 0 0 0 4 6c0 .628-.134 2.197-.459 3.742-.16.767-.376 1.566-.663 2.258h10.244c-.287-.692-.502-1.49-.663-2.258C12.134 8.197 12 6.628 12 6a4.002 4.002 0 0 0-3.203-3.92L8 1.917zM14.22 12c.223.447.481.801.78 1H1c.299-.199.557-.553.78-1C2.68 10.2 3 6.88 3 6c0-2.42 1.72-4.44 4.005-4.901a1 1 0 1 1 1.99 0A5.002 5.002 0 0 1 13 6c0 .88.32 4.2 1.22 6z" />
                                        </svg>
                                        <span className="icon-badge notification-count"></span>
                                    </span>

                                    <div className="dropdown-menu p-0" aria-labelledby="notifications-dropdown-toggle">
                                        <div className="dropdown-menu-header p-3">
                                            <h5 className="dropdown-menu-title mb-0">Notifications</h5>
                                        </div>
                                        <div className="dropdown-menu-content">
                                            <div className="item p-3">
                                                <div className="row gx-2 justify-content-between align-items-center">
                                                    <div className="col-auto">
                                                        <img className="profile-image" src="/asset/image/noprofileimage.png" alt="" />
                                                    </div>
                                                    <div className="col">
                                                        <div className="info">
                                                            <div className="desc">Upcoming Version</div>
                                                            <div className="meta"> 2 approvals waiting</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="dropdown-menu-footer p-2 text-center">
                                            <a href="#notification">View all</a>
                                        </div>

                                    </div>
                                </div>
                                <div className="app-utility-item">
                                    <a href="#settings" title="Settings">

                                        <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-gear icon" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd" d="M8.837 1.626c-.246-.835-1.428-.835-1.674 0l-.094.319A1.873 1.873 0 0 1 4.377 3.06l-.292-.16c-.764-.415-1.6.42-1.184 1.185l.159.292a1.873 1.873 0 0 1-1.115 2.692l-.319.094c-.835.246-.835 1.428 0 1.674l.319.094a1.873 1.873 0 0 1 1.115 2.693l-.16.291c-.415.764.42 1.6 1.185 1.184l.292-.159a1.873 1.873 0 0 1 2.692 1.116l.094.318c.246.835 1.428.835 1.674 0l.094-.319a1.873 1.873 0 0 1 2.693-1.115l.291.16c.764.415 1.6-.42 1.184-1.185l-.159-.291a1.873 1.873 0 0 1 1.116-2.693l.318-.094c.835-.246.835-1.428 0-1.674l-.319-.094a1.873 1.873 0 0 1-1.115-2.692l.16-.292c.415-.764-.42-1.6-1.185-1.184l-.291.159A1.873 1.873 0 0 1 8.93 1.945l-.094-.319zm-2.633-.283c.527-1.79 3.065-1.79 3.592 0l.094.319a.873.873 0 0 0 1.255.52l.292-.16c1.64-.892 3.434.901 2.54 2.541l-.159.292a.873.873 0 0 0 .52 1.255l.319.094c1.79.527 1.79 3.065 0 3.592l-.319.094a.873.873 0 0 0-.52 1.255l.16.292c.893 1.64-.902 3.434-2.541 2.54l-.292-.159a.873.873 0 0 0-1.255.52l-.094.319c-.527 1.79-3.065 1.79-3.592 0l-.094-.319a.873.873 0 0 0-1.255-.52l-.292.16c-1.64.893-3.433-.902-2.54-2.541l.159-.292a.873.873 0 0 0-.52-1.255l-.319-.094c-1.79-.527-1.79-3.065 0-3.592l.319-.094a.873.873 0 0 0 .52-1.255l-.16-.292c-.892-1.64.902-3.433 2.541-2.54l.292.159a.873.873 0 0 0 1.255-.52l.094-.319z" />
                                            <path fillRule="evenodd" d="M8 5.754a2.246 2.246 0 1 0 0 4.492 2.246 2.246 0 0 0 0-4.492zM4.754 8a3.246 3.246 0 1 1 6.492 0 3.246 3.246 0 0 1-6.492 0z" />
                                        </svg>
                                    </a>
                                </div>

                                <div className="app-utility-item app-user-dropdown dropdown">
                                    <span className="dropdown-toggle" id="user-dropdown-toggle" data-bs-toggle="dropdown" role="button" aria-expanded="false"><img className="employee_profile" src="/asset/image/noprofileimage.png" alt="user profile" /></span>

                                    <ul className="dropdown-menu" aria-labelledby="user-dropdown-toggle">
                                        <li className="h5"><span className="dropdown-item badge bg-success employee_name">{userInfo.name}</span></li>
                                        <li><span className="dropdown-item"><i className="fas fa-mobile-alt"></i> {userInfo.mobile}<span className="employee_mobile_no"></span></span></li>
                                        <li><span className="dropdown-item"><i className="far fa-envelope"></i> {userInfo.email}<span className="employee_email"></span></span></li>
                                        <li><hr className="dropdown-divider" /></li>
                                        <li><a className="dropdown-item" href="#core_user">Account</a></li>
                                        <li><a className="dropdown-item" href="#settings">Settings</a></li>
                                        <li><hr className="dropdown-divider" /></li>
                                        <li><span className="dropdown-item" onClick={logout} >Log Out</span></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        <Sidebar></Sidebar>
        </div>

    )
}

export default Topbar
